<script lang="ts">
  import {FindQaId, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {original} from "../../store/SuggestionStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {allowSubmit} from "../../store/Derived";
  import {onDestroy} from "svelte";
  import type {Unsubscriber} from "svelte/store";
  import {COLOR} from "../../constants/color";
    import {SQRL_SUGGESTION_TYPE} from "../../constants/squirrel";

  const {value, target, isEmpty} = searchfieldStore;
  let unsubscribe: Unsubscriber;

  /**
 *
 *
 *
 *
 *
 */

  function handleSubmit() {
    if ($isEmpty) {
      searchfieldStore.isSuggestOpen.set(true);
      return;
    }
    unsubscribe = allowSubmit.subscribe((allowSubmit) => {
      if (allowSubmit) {
        submitStore.handleSubmit({term: $value}, $target, SUBMIT_TYPE.SUBMIT_BUTTON, $original.type as SQRL_SUGGESTION_TYPE?? SQRL_SUGGESTION_TYPE.SEARCH);
      }
    });
  }

  onDestroy(() => {
    if (unsubscribe != undefined) {
      unsubscribe();
    }
  });
</script>

<oc-icon-button-v2 class="squirrel_submit-button squirrel_searchbar__submit js_squirrel_searchbar__submit"
                   data-testid={SqrlTestId.SUBMIT_BUTTON}
                   data-qa-id={SqrlQaId.SUBMIT_BUTTON}
                   data-qa={FindQaId.SUBMIT_BUTTON}
                   class:squirrel_active={!$isEmpty}
                   icon="search"
                   icon-color={$isEmpty ? COLOR.BLACK_100: COLOR.WHITE}
                   variant="inverted-transparent"
                   oc-aria-label="Suche abschicken"
                   onclick={handleSubmit}
                   tabindex={$isEmpty ? -1 : null}
></oc-icon-button-v2>

<style lang="scss">
  @use "~@otto-ec/design-tokens/tokens";

  .squirrel_active {
    background-color: tokens.$oc-base-color-otto-red-125;
    border-radius: 50%;
  }
</style>
