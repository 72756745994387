export enum KEY_CODE {
  ALT = "Alt",
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
  ARROW_UP = "ArrowUp",
  BACKSPACE = "Backspace",
  ENTER = "Enter",
  ESC = "Escape",
  SHIFT = "Shift",
  TAB = "Tab",
}
