<script lang="ts">
  import {navSlider} from "./NavSlider";
  import {eventQBus} from "@otto-ec/event-q-bus";
  import {FTNAV_EVENT} from "../../../events/ftnavEvents";
  import {sendTrackingLabelOnFocus} from "../../../store/TrackingStore";
  import {tracking} from "../../../tracking/Tracking";
  import {SQRL_EVENT} from "../../../events/sqrlEvents";
  import type {FTNavEvents} from "../../../types/ftnav";

  const bus = eventQBus<FTNavEvents>();

  /**
 *
 *
 *
 *
 */
  function renderNavSlider(node: Node) {
    node.appendChild(navSlider.template);
    if ($sendTrackingLabelOnFocus) {
      bus.once(FTNAV_EVENT.REGISTERED_SLIDER, (id: string) => {
        if (id === "squirrel_nav_slider_wrapper") {
          navSlider.updateHTMLTemplateOnce();
        tracking.trackCreateEventContext(SQRL_EVENT.SEARCH_FOCUS);
        }
      });
      bus.emit(FTNAV_EVENT.REGISTER_SLIDER, "squirrel_nav_slider_wrapper");
    } else {
      bus.emit(FTNAV_EVENT.REGISTER_SLIDER, "squirrel_nav_slider_wrapper", {empty_suggest_notrack: true});
    }
  }


</script>

<div use:renderNavSlider></div>
