/*                                                           */
/*                      */
/*                */
/*                */
import type { Response } from '../models/Response';
import type { SuggestResponse } from '../models/SuggestResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class IndexControllerService {
    /**
 *
 *
 *
 *
 *
 *
 */
    public static query(
        q: string,
        history?: string,
        c?: number,
        positionOffset?: number,
    ): CancelablePromise<(Response | SuggestResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/san-squirrel-suggest-api/completion',
            query: {
                'q': q,
                'history': history,
                'c': c,
                'positionOffset': positionOffset,
            },
        });
    }
}
