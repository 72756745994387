import { tracking } from "../../../tracking/Tracking";
import { tlSearchBoxActionClick } from "../../../tracking/labels/SearchBoxAction";
import type { EventMergeContext } from "@otto-ec/tracking-bct";

export class NavSlider {
  private _template: HTMLDivElement | null = null;
  private updated = false;
  private getHTMLTemplate(): HTMLDivElement {
    const navSliderContainer = document.querySelector(
      ".js_squirrel_navSliderContainer"
    ) as HTMLDivElement;
    navSliderContainer.removeAttribute("style");
    const template: HTMLDivElement = navSliderContainer;
    /*                                                                                    */
    navSliderContainer.outerHTML = "";
    return template;
  }

  public updateHTMLTemplateOnce(): void {
    if (!this.updated) {
      this._template = document.querySelector(".js_squirrel_navSliderContainer") as HTMLDivElement;
      this.updated = true;
    }
  }

  get template(): HTMLDivElement {
    if (!this._template) {
      this._template = this.getHTMLTemplate();
    }
    return this._template;
  }

  onMergeEventContext(mergeContext: EventMergeContext) {
    tracking.trackSubmitEventMerge(tlSearchBoxActionClick.data, mergeContext);
  }
}

export const navSlider = new NavSlider();
