import {get} from "svelte/store";
import {submitHistory} from "../../store/HistoryStore";
import {TrackingLabel} from "../TrackingLabel";

export enum SearchHistoryDeleteValue {
  ALL = "all",
}

export class SearchHistoryDelete extends TrackingLabel<SearchHistoryDeleteValue[]> {

  get name(): string {
    return "san_SearchHistoryDelete";
  }

  get value(): SearchHistoryDeleteValue[] {
    return [SearchHistoryDeleteValue.ALL];
  }

  isTrackable(): boolean {
    return this.value.length !== 0 && get(submitHistory.store).length > 0;
  }
}

export const tlSearchHistoryDelete = new SearchHistoryDelete();
