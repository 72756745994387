<script lang="ts">
  import type {Suggestion} from "../../generated";
  import {type SqrlListIconModel, type SqrlListItemModel} from "../../types/squirrel";
  import {onDestroy} from "svelte";
  import {Label} from "../../constants/label";
  import {SqrlTestId, SQRL_SUGGESTION_TYPE} from "../../constants/squirrel";
  import {keywords} from "../../store/SuggestionStore";
  import {timeEmptyState, timeSuggestRender} from "../../times/TimeFactory";
  import {indexStore} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {COLOR} from "../../constants/color";
  import SqrlListItem from "../interactive/listItem/SqrlListItem.svelte";

  timeEmptyState.mark()

  const rightIcon: SqrlListIconModel = {
    icon: "arrow-right",
    style: {color: COLOR.RED_100},
    classNames: ["squirrel_arrow_right-icon"],
    label: "Suche abschicken",
  };
  const leftIcon: SqrlListIconModel = {
    icon: "arrow-graph",
    label: "Beliebter Suchbegriff",
  };

  const unsubscribe = keywords.subscribe((keywords) => {
    indexStore.updateMaxIndex(keywords.length);
  })

  function handleClick(suggestion: Suggestion) {
    timeEmptyState.measure();
    submitStore.handleSubmit({term: suggestion.term}, suggestion.target, SUBMIT_TYPE.TRENDING_QUERY_ITEM, suggestion.type as SQRL_SUGGESTION_TYPE?? SQRL_SUGGESTION_TYPE.SEARCH);
  }

  function createModel(trendingQuery: Suggestion, index: number): SqrlListItemModel {
    return {name: "trendingQuery", suggestion: trendingQuery, index, leftIcon, rightIcon};
  }

  timeSuggestRender.measure();
  onDestroy(() => {
    timeEmptyState.measure()
    unsubscribe();
  })
</script>

<div class="squirrel_trendingQueries" data-testid={SqrlTestId.TRENDING_QUERY_LIST}>
    <div class="pl_table-view--headline squirrel_searchSuggestions__headline">
        <span class="oc-copy-75">{Label.TRENDING_QUERY_HEADER}</span>
    </div>
    <ul class="squirrel_trendingQueries__list squirrel_border_top">
        {#each $keywords as trendingQuery, index}
            <SqrlListItem params={createModel(trendingQuery, index)}
                            onClickHandler={handleClick}/>
        {/each}
    </ul>
</div>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_trendingQueries {
    background-color: tokens.$oc-base-color-white;
    padding: tokens.$oc-semantic-spacing-0;
    border-bottom-left-radius: tokens.$oc-semantic-border-radius-100;
    border-bottom-right-radius: tokens.$oc-semantic-border-radius-100;
  }

  .squirrel_searchSuggestions__headline {
    padding: tokens.$oc-semantic-spacing-150 tokens.$oc-semantic-spacing-100 tokens.$oc-semantic-spacing-50;
    height: fit-content;
    color: tokens.$oc-base-color-gray-400;
  }
</style>
