<script lang="ts">
  import {FindQa, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {KEY_CODE} from "../../constants/keyCode";
  import {eventQBus} from "@otto-ec/event-q-bus";
  import {timeInitSearchField, timeSuggest, timeSuggestRender} from "../../times/TimeFactory";
  import {activeIndex, indexStore, isSuggestTypeFrozen} from "../../store/IndexStore.js";
  import {original, keywords} from "../../store/SuggestionStore";
  import {sendTrackingLabelOnFocus} from "../../store/TrackingStore";
  import {tlInitialSearchBoxTime} from "../../tracking/labels/InitialSearchBoxTime";
  import {onDestroy} from "svelte"
  import {Label} from "../../constants/label";
  import {SQRL_EVENT} from "../../events/sqrlEvents";
  import {submitStore} from "../../store/SubmitStore";
  import {suggestType} from "../../store/Derived";
  import {SuggestType} from "../../constants/suggestType";
  import {visualSearches} from "../../store/HistoryStore";

  interface Props {
    sendRequestCallback: (term: string, cursorPosition: number) => void;
  }

  let {sendRequestCallback}: Props = $props();

  const bus = eventQBus<never>();
  let inputElement: HTMLInputElement = $state();
  let {value, target, isFocus, isEmpty, isSuggestOpen} = searchfieldStore;
  let maxlength = 150;

  /**
 *
 */
  const unsubscribe = isEmpty.subscribe((value: boolean) => {
    if (value) {
      timeSuggest.reset()
    }
  })

  function getCursorPosition() {
    const selectionStart = inputElement.selectionStart;
    const termLength: number = $value.length;
    return (selectionStart && selectionStart <= termLength) ? selectionStart : termLength;
  }

  /**
 *
 *
 *
 *
 *
 */
  function handleInput() {
    sendRequestCallback($value, getCursorPosition())
    if (!$value) {
      indexStore.reset()
    }
    $sendTrackingLabelOnFocus = false;
    $isSuggestOpen = true;
  }

  /**
 *
 *
 *
 *
 */
  function handleFocus() {
    timeSuggestRender.mark();
    if (!$value) {
      sendRequestCallback("", 0)
    } else if ($value !== $original.term && !$isSuggestTypeFrozen) {
      sendRequestCallback($value, inputElement.selectionEnd??0)
    }
    if (!$isFocus) {
      $isFocus = true;
    }
    if (!$isSuggestOpen) {
      $isSuggestOpen = true;
      timeInitSearchField.measureOnce(tlInitialSearchBoxTime.sessionStart!)
    }
  }

  function closeSuggest() {
    $isSuggestOpen = false;
    $isFocus = false;
    inputElement?.blur();
    submitStore.$breadcrumbSearch = false;
  }

  bus.on(SQRL_EVENT.SUGGEST_CLOSE, () => {
    closeSuggest();
  });

  bus.on(SQRL_EVENT.SUGGEST_OPEN, () => {
    handleFocus();
  });


  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  function handleKeydown(event: KeyboardEvent) {
    timeSuggestRender.mark();
    if ($suggestType == SuggestType.CLOSED) {
      return;
    }

    function updateValue() {
      if ($activeIndex !== -1) {
        if ($suggestType === SuggestType.HISTORY) {
          $value = $visualSearches[$activeIndex].term;
        } else {
          $value = $keywords[$activeIndex].term;
        }
      }
    }

    if (event.code === KEY_CODE.ESC || event.key === KEY_CODE.ESC) {
      closeSuggest();
    }

    if (event.code === KEY_CODE.ARROW_UP) {
      event.preventDefault();
      indexStore.previous();
      updateValue();
      return;
    } else if (event.code === KEY_CODE.ARROW_DOWN) {
      event.preventDefault();
      indexStore.next();
      updateValue();
      return;
    } else if ([KEY_CODE.ARROW_LEFT, KEY_CODE.ARROW_RIGHT, KEY_CODE.TAB, KEY_CODE.BACKSPACE, KEY_CODE.ENTER].includes(event.code as KEY_CODE)) {
      return;
    } else if (event?.key?.length !== 1 && $isSuggestTypeFrozen) {
      event.preventDefault();
      return;
    }
    indexStore.reset();
  }

  /**
 *
 */
  function handleFocusout() {
    indexStore.reset();
  }

  onDestroy(unsubscribe);

</script>
<span id="squirrel_searchfieldDescription">Verwende die Pfeiltasten, um Suchvorschläge oder deine Suchhistorie auszuwählen.</span>
<input
        class="squirrel_searchfield js_squirrel_searchbar__input oc-copy-125"
        data-qa={FindQa.SEARCH_FIELD}
        data-qa-id={SqrlQaId.SEARCH_FIELD}
        data-testid={SqrlTestId.SEARCH_FIELD}
        data-target={$target}
        autocomplete="off"
        type="text"
        {maxlength}
        placeholder={Label.PLACEHOLDER}
        bind:this={inputElement}
        bind:value={$value}
        oninput={handleInput}
        onfocusin={handleFocus}
        onfocusout={handleFocusout}
        onkeydown={handleKeydown}
        role="searchbox"
        tabindex="0"
        aria-describedby="squirrel_searchfieldDescription"/>
