import { type Writable, writable } from "svelte/store";

export class TrackingStore {
  sendTrackingLabelOnFocus: Writable<boolean> = writable(true);

  set $sendTrackingLabelOnFocus(value: boolean) {
    this.sendTrackingLabelOnFocus.set(value);
  }
}

export const trackingStore = new TrackingStore();
export const sendTrackingLabelOnFocus = trackingStore.sendTrackingLabelOnFocus;
