import {TrackingLabel} from "../TrackingLabel";
import type {HistoryItem} from "../../types/global";
import {submitHistory} from "../../store/HistoryStore";
import {get} from "svelte/store";

export class SearchHistoryDeletedTerms extends TrackingLabel {
  readonly name: string = "san_SearchHistoryDeletedTerms";

  get value(): string[] {
    return [
      get(submitHistory.store)
        .map((historyItem: HistoryItem) => historyItem.term)
        .join("|"),
    ];
  }

  isTrackable(): boolean {
    return this.value.length > 0;
  }
}

export const tlSearchHistoryDeletedTerms = new SearchHistoryDeletedTerms();
