import type { DataContainer } from "@otto-ec/tracking-bct";
import { TrackingLabel } from "../TrackingLabel";

export enum SEARCH_BOX_ACTION {
  CLICK = "click",
  CHAR = "character",
}

export class SearchBoxAction extends TrackingLabel<SEARCH_BOX_ACTION[]> {
  readonly name: string = "san_SearchBoxAction";
  private isTracked: boolean = false;
  constructor(
    private _value: SEARCH_BOX_ACTION,
    private _isTrackable: boolean = false,
  ) {
    super();
  }
  override get data(): DataContainer {
    const data = super.data;
    if (this._value === SEARCH_BOX_ACTION.CHAR) {
      this.isTracked = true;
    }
    return data;
  }

  get value(): SEARCH_BOX_ACTION[] {
    return [this._value];
  }

  /*             */
  setIsTracked(isTracked: boolean) {
    this.isTracked = isTracked;
  }
  setIsTrackable(isTrackable: boolean) {
    this._isTrackable = isTrackable;
  }
  isTrackable(): boolean {
    return this._isTrackable && !this.isTracked;
  }
}
export const tlSearchBoxActionClick = new SearchBoxAction(SEARCH_BOX_ACTION.CLICK, true);
export const tlSearchBoxActionCharacter = new SearchBoxAction(SEARCH_BOX_ACTION.CHAR, true);
