<script lang="ts">
  import type {Optional, SqrlListIconModel} from "../../../types/squirrel";
  import {SqrlTestId} from "../../../constants/squirrel";

  interface Props {
    params: Optional<SqrlListIconModel>;
  }

  let { params }: Props = $props();

  const color = params?.style?.color;
</script>
{#if params !== undefined && params.icon}
    <div class="squirrel_icon {params?.classNames?.join(' ') || ''}"
         data-qa-id={params.qaId}
         data-testid={params.testId || SqrlTestId.LIST_ICON}>
        <oc-icon-v1 type={params.icon} oc-aria-label={params.label} size="100" color={color}></oc-icon-v1>
    </div>
{/if}

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_icon {
    display: flex;
    color: tokens.$oc-base-color-black-100;
    cursor: pointer;
    width: min-content;
    height: max-content;
    padding: tokens.$oc-semantic-spacing-75 tokens.$oc-semantic-spacing-100;
  }
</style>