<script lang="ts">
  import {Label} from "../../../constants/label";
  import {submitHistory} from "../../../store/HistoryStore";
  import {tracking} from "../../../tracking/Tracking";
  import {tlSearchHistoryDeletedTerms} from "../../../tracking/labels/SearchHistoryDeletedTerms";
  import {tlSearchHistoryDelete} from "../../../tracking/labels/SearchHistoryDelete";

  function deleteHistory() {
    tracking.trackSubmitEvent({
      ...tlSearchHistoryDeletedTerms.data,
      ...tlSearchHistoryDelete.data,
    });
    submitHistory.deleteHistory();
  }

  function handleDeletePress(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      submitHistory.deleteHistory();
    }
  }

</script>

<li class="squirrel_searchHistoryDelete js_squirrel_ignore_suggest">
    <div class="js_squirrel_ignore_suggest"
         onclick={deleteHistory}
         tabindex="0"
         role="button"
         onkeydown={handleDeletePress}>
        <div
            data-qa-id="search-history-delete"
            class="squirrel_searchHistoryDelete__text js_squirrel_searchHistoryDelete js_squirrel_ignore_suggest">
            <oc-icon-v1 oc-aria-label="Suchhistorie löschen" type="delete"></oc-icon-v1>
            <span
                class="oc-copy-100 squirrel_searchHistoryDelete__term js_squirrel_searchHistory__term js_squirrel_ignore_suggest"
            >{Label.HISTORY_DELETE_ALL_BUTTON}</span>
        </div>
    </div>
</li>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  oc-icon-v1 {
    padding: tokens.$oc-semantic-spacing-100;
    width: tokens.$oc-base-dimension-56;
    height: tokens.$oc-base-dimension-56;
    fill: tokens.$oc-semantic-color-text-interactive;
  }

  .squirrel_searchHistoryDelete {
    position: relative;
    cursor: pointer;
    background-color: tokens.$oc-base-color-white;

    &:not(:last-child) {
      > div {
        border-bottom: tokens.$oc-semantic-border-width-100 solid tokens.$oc-base-color-gray-25;
      }
    }

    &__text {
      display: flex;
      height: tokens.$oc-base-dimension-56;
      justify-content: center;
      align-items: center;
      color: tokens.$oc-semantic-color-text-interactive;

      > .squirrel_searchHistoryDelete__term {
        margin-right: tokens.$oc-base-dimension-40;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
