<script lang="ts">
  import {SuggestType} from "../../constants/suggestType";
  import SuggestionList from "./SuggestionList.svelte";
  import TrendingQueryList from "./TrendingQueryList.svelte";
  import NavSlider from "./navSlider/NavSlider.svelte";
  import {suggestType} from "../../store/Derived";
  import {SqrlTestId} from "../../constants/squirrel";
  import HistoryList from "./history/HistoryList.svelte";

</script>

<div
  class="squirrel_searchSuggestionsContainer js_squirrel_searchSuggestionsContainer"
  data-qa-id="search-suggestions"
  data-suggestserveruri="/san-squirrel-suggest-api/completion"
  data-testid={SqrlTestId.SUGGEST_LAYER}
>
  {#if $suggestType === SuggestType.SUGGEST}
    <SuggestionList />
  {:else if $suggestType === SuggestType.HISTORY}
    <NavSlider />
    <HistoryList />
  {:else if $suggestType === SuggestType.TRENDING_QUERY}
    <NavSlider  />
    <TrendingQueryList />
  {/if}
</div>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";
  @use "@otto-ec/otto-components-utils/scss/_mixins/breakpoint";
  @use "../../styles/constants";

  .squirrel_searchSuggestionsContainer {
    width: 100%;
    position: absolute;
    left: tokens.$oc-semantic-spacing-0;
    z-index: 101;
    height: fit-content;
    overflow-x: auto;
    border-bottom: tokens.$oc-semantic-border-width-100;
    border-color: tokens.$oc-base-color-gray-150;
    border-style: solid;

    @include breakpoint.breakpoint-from-l($to: false) {
      top: tokens.$oc-base-dimension-72;
      border-bottom-left-radius: tokens.$oc-semantic-border-radius-100;
      border-bottom-right-radius: tokens.$oc-semantic-border-radius-100;
      border-width: 1px;
      border-top: unset;
    }
  }
</style>
