<script lang="ts">
  import {isFocus, isSuggestOpen, value} from "./store/SearchfieldStore";
  import {isActive} from "./store/SubmitStore";
  import SearchLayer from "./components/searchLayer/SearchLayer.svelte";
  import SuggestLayer from "./components/suggestLayer/SuggestLayer.svelte";
  import {FindQa, SqrlQaId} from "./constants/squirrel";
  import {sendTrackingLabelOnFocus} from "./store/TrackingStore";
  import {encodedHistory, isEmpty} from "./store/HistoryStore";
  import {tracking} from "./tracking/Tracking";
  import {tlSearchBoxActionCharacter} from "./tracking/labels/SearchBoxAction";
  import {SanHeader} from "./events/sanHeader";
  import {onDestroy} from "svelte";
  import {searchHandler} from "./searchHandler";
  import {sqrlRequest} from "./util/SqrlRequest";


  /**
 *
 */
  searchHandler.init();

  /**
 *
 *
 *
 *
 *
 */
  let firstFocus = true;
  const unsubscribeFocus = isFocus.subscribe((isFocusValue) => {
    if (firstFocus) {
      firstFocus = false;
      return;
    }
    if (!isFocusValue) {
      tracking.trackSubmitEvent({san_Header: [SanHeader.CANCEL]});
    }
  });

  /**
 *
 */
  const unsubscribeValue = value.subscribe((value) => {
    if (!value) {
      tlSearchBoxActionCharacter.setIsTrackable(true);
      tlSearchBoxActionCharacter.setIsTracked(false);
    } else if (tlSearchBoxActionCharacter.isTrackable() && value && $isActive) {
      tracking.trackSubmitEvent(tlSearchBoxActionCharacter.data);
      tlSearchBoxActionCharacter.setIsTrackable(false);
    }
  });

  /**
 *
 *
 *
 *
 *
 *
 */
  export function focusOut(node: Node) {
    const handleFocus = (event: FocusEvent) => {
      if (
        node &&
        !node.contains(event.target as Node) &&
        !event.defaultPrevented &&
        $isSuggestOpen
      ) {
        $isSuggestOpen = false;
        $sendTrackingLabelOnFocus = !$value;
      }
    };

    document.addEventListener("focusin", handleFocus, true);
    document.addEventListener("click", handleFocus, true);
    return {
      destroy() {
        document.removeEventListener("focusin", handleFocus, true);
        document.removeEventListener("click", handleFocus, true);
      }
    };
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  function sendRequest(term: string, cursorPosition: number) {
    sqrlRequest.fetch(term, cursorPosition, $isEmpty ? undefined : encodedHistory(), false)
  }

  /**
 *
 */
  onDestroy(() => {
    unsubscribeFocus();
    unsubscribeValue();
  });
</script>

<div class="squirrel_searchbarWrapper js_squirrel_searchbarWrapper"
     data-qa-id={SqrlQaId.WRAPPER}
     data-qa={FindQa.WRAPPER}
     use:focusOut>
    <SearchLayer sendRequestCallback={sendRequest}/>
    {#if $isSuggestOpen}
        <SuggestLayer/>
    {/if}
</div>

